var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          attrs: {
            headers: _vm.tokenHeader,
            name: "files",
            action: _vm.baseURL + "/Files/Upload",
            multiple: true,
            "on-success": _vm.uploadSuccess,
            "on-exceed": _vm.limitExceed,
            limit: _vm.uploadType.limit,
            "auto-upload": true,
            "file-list": _vm.fileList,
            "list-type": "picture-card",
            "before-upload": _vm.beforeUpload,
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function ({ file }) {
                return _c("div", { staticClass: "img_div" }, [
                  _c("img", {
                    staticClass: "el-upload-list__item-thumbnail",
                    attrs: { src: file.url, alt: "" },
                  }),
                  _c("span", { staticClass: "img_name" }, [
                    _vm._v(_vm._s(file.name)),
                  ]),
                  _c("span", { staticClass: "el-upload-list__item-actions" }, [
                    !_vm.isFile(file)
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-preview",
                            on: {
                              click: function ($event) {
                                return _vm.handlePictureCardPreview(file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        )
                      : _vm._e(),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e(),
                  ]),
                ])
              },
            },
          ]),
        },
        [
          _c("i", { staticClass: "el-icon-plus" }),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              staticStyle: { color: "red" },
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v("*上传文件不能超过 " + _vm._s(_vm.defaultSize) + " MB")]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }