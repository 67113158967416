<template>
  <div>
    <el-upload  :headers="tokenHeader" name="files" :action="baseURL +'/Files/Upload'" ref="upload" :multiple="true" :on-success="uploadSuccess" :on-exceed="limitExceed" :limit="uploadType.limit" :auto-upload="true" :file-list="fileList" list-type="picture-card" :before-upload="beforeUpload">
      <i class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}" class="img_div">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
        <span class="img_name">{{file.name}}</span>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)" v-if="!isFile(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)" >
            <i class="el-icon-download"></i>
          </span> -->
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)" >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <div class="el-upload__tip" slot="tip" style="color:red;">*上传文件不能超过 {{defaultSize}} MB</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import fileImg from '@/assets/file.png'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      tokenHeader: {'X-Token': getToken()},
      baseURL: process.env.VUE_APP_BASE_API, // api的base_url做
      baseImgUrl: process.env.VUE_APP_BASE_IMG_URL, // 图片根目录
      defaultSize:5,//默认文件大小限制
      dialogImageUrl: '',//弹窗图片路径
      dialogVisible: false,//弹窗图片状态
      disabled: false,//是否显示下载、删除
      fileList:[],
      fileImg:fileImg
    }
  },
  props:{
    uploadType:[String, Object],
  },
  // props:['uploadType'],
  methods: {
    //上传成功
    uploadSuccess(response, file, fileList){
      if(response.code != 200){
        for (let i = 0; i < fileList.length; i++) {
          if(fileList[i].name == file.name){
            fileList.splice(i,1);
            this.$message({
              type: 'warning',
              message: response.message,
              duration: 1000
            });
          }
        }
      }
      this.fileList = fileList;
      const isJPEG =  'jpeg';
      const isJPG =  'jpg';
      const isPNG =  'png';
      for (let i = 0; i < fileList.length; i++) {
        var testName = fileList[i].name.substring(fileList[i].name.lastIndexOf('.')+1)
        if(testName != isJPEG && testName != isJPG && testName != isPNG ){
          fileList[i].url=fileImg
        }
      }
    },
    //文件超出个数限制
    limitExceed(files, fileList){
      this.$message.warning(`当前限制选择 ${this.uploadType.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //上传文件之前
    beforeUpload(file) {
      // uploadType.type:file:文件,img:图片
      const isLt2M = file.size / 1024 / 1024 < this.defaultSize;
      // var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
      // const isJPG = file.type === 'image/jpeg';
      // const isPNG = file.type === 'image/png';
      // const isTxt = file.type === 'text/plain';
      // const isXls = testmsg === 'xls'
      // const isXlsx = testmsg === 'xlsx' 
      // const isDoc = testmsg === 'doc'
      // const isDocx = testmsg === 'docx' 
      // const isFile = !isXls && !isXlsx && !isDoc && !isDocx && !isTxt
      // const isImg = !isJPG && !isPNG
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 '+ this.defaultSize + 'MB!');
        return isLt2M;
      }
      // if(this.uploadType.type == 'file'){
      //   if (isFile) {
      //     this.$message.error('上传文件只能是 xls、xlsx、doc、docx、text 格式!');
      //     return !isFile&& isLt2M;
      //   }
      // }else if(this.uploadType.type == 'img'){
      //   if (isImg) {
      //     this.$message.error('上传头像图片只能是 JPG、PNG 格式!');
      //     return !isImg&& isLt2M;
      //   }
      // }
    },
    //删除图片
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.$message({
        type: 'info',
        message: '已删除原有图片',
        duration: 1000
      });
    },
    //下载
    //点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //判断是图片还是文件
    isFile(file){
      const isJPEG =  'jpeg';
      const isJPG =  'jpg';
      const isPNG =  'png';
      const testName = file.name.substring(file.name.lastIndexOf('.')+1)
      var s = false;
      if(testName != isJPEG && testName != isJPG && testName != isPNG ){
        s = true
      }else{
        s = false
      }
      return s;
    },
  },
  created() {
    if(this.uploadType.fileSize){
      this.defaultSize = this.uploadType.fileSize
    }
  },
  mounted() {
    
  },
}
</script>
<style scoped>
  .click_upload{
    color: #1a96d4;
    font-style: normal;
    font-size: 14px;
  }
  .bright_icon{
    color: #1a96d4;
  }
  .img_div{
    position: relative;
  }
  .img_name{
    display: block;
    background: rgba(204, 204, 204, .8);
    color: #fff;
    padding: 0 2px;
    text-overflow : ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: absolute;
    bottom: 5px;
  }
</style>
